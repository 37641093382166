import React from 'react';
import ImageFallback from './ImageFallback';
import Spinner from '../Spinner';
import { ImageFit, ImageAspect } from './types';
import {useImageLoader} from './useImageLoader';

export interface ImageContainerProps {
  src: string | Promise<string>;
  altText?: string;
  hasHover?: boolean;
  fit?: ImageFit;
  aspect?: ImageAspect;
}

const getContainerClass = (aspect: ImageAspect): string =>
  `relative bg-gray-200 rounded w-full overflow-hidden aspect-${aspect}
   after:content-[""] after:absolute after:inset-0 after:rounded after:border
   after:border-black/10 after:pointer-events-none`;

const getBlurImageClasses = (showFallback: boolean, hasHover: boolean): string =>
  [
    'absolute w-full h-full object-cover',
    showFallback ? 'opacity-0' : 'opacity-100',
    hasHover ? 'group-hover:brightness-50 blur-md hover:brightness-50' : 'blur-md',
  ].join(' ');

const getPreviewClasses = (showFallback: boolean, hasHover: boolean, fit: ImageFit): string =>
  [
    'relative w-full h-full',
    `object-${fit}`,
    showFallback ? 'opacity-0' : 'opacity-100',
    hasHover ? 'group-hover:brightness-50 hover:brightness-50' : '',
  ].join(' ');

const ImageContainer: React.FC<ImageContainerProps> = ({
  src,
  altText,
  hasHover = false,
  fit = 'contain',
  aspect = 'square',
}) => {
  const { resolvedSrc, isLoaded, showFallback } = useImageLoader(src);
  const containerClass = getContainerClass(aspect);
  const blurImageClasses = getBlurImageClasses(showFallback, hasHover);
  const previewClasses = getPreviewClasses(showFallback, hasHover, fit);

  if (!isLoaded) {
  return (
    <div className={containerClass}>
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="w-8 h-8 flex flex-col items-center justify-center">
          <Spinner active color="gray" size="24" />
        </div>
    </div>
    </div>
  );
  }

  return (
    <div className={containerClass}>
    {showFallback && <ImageFallback />}
    {resolvedSrc && (
    <>
      <img
      src={resolvedSrc}
      alt="background"
      className={blurImageClasses}
      style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
      />
      <img
      src={resolvedSrc}
      alt={altText}
      className={previewClasses}
      style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
      />
    </>
    )}
    </div>
  );
};

export default ImageContainer;
